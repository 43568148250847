import React, { useEffect, useState } from "react"
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  endOfDay,
  endOfHour,
  endOfMinute,
  formatDuration,
  intervalToDuration,
  isBefore,
  startOfDay,
  startOfHour,
} from "date-fns"

import timTitleImg3 from "../images/sparkleTitle_730.png"
import pineWreath from "../images/pineWreath_389.png"

import css from "./LoggedOut.module.css"
import FeatureList from "./FeatureList"

function timeTill(now, targetDateString) {
  const then = new Date(targetDateString)

  if (now < then) {
    const readableDuration = formatDuration(
      intervalToDuration(
        {
          start: now,
          end: then,
        },
        { format: ["days", "hours", "minutes", "seconds"] }
      )
    )

    return readableDuration
      .replace(" days", "d")
      .replace(" day", "d")
      .replace(" hours", "h")
      .replace(" hour", "h")
      .replace(" minutes", "m")
      .replace(" minute", "m")
      .replace(" seconds", "s")
      .replace(" second", "s")
  }
}

// function Landing() {
//   return (
//     <div className="flex flex-col items-center justify-center w-screen">
//       <div className="min-w-min max-w-xl mt-4 sm:mt-8 mx-auto">
//         <h2 className="border-gray-900 dark:border-gray-400 border-t-4 border-b-4 mb-8 mx-auto rounded-md text-2xl dark:text-blue-50 text-center w-1/2">
//           Do You See What I See?
//         </h2>
//         <h2 className="border-gray-900 dark:border-gray-400 border-t-4 border-b-4 mb-8 mx-auto rounded-md text-2xl dark:text-blue-50 text-center w-1/2">
//           Donate to Cystic Fibrosis Resesarch
//         </h2>
//         <h2 className="border-gray-900 dark:border-gray-400 border-t-4 border-b-4 mb-8 mx-auto rounded-md text-2xl dark:text-blue-50 text-center w-1/2">
//           About Us
//         </h2>
//       </div>
//     </div>
//   )
// }

function LoggedOut() {
  const areLightsOn = isBefore(
    new Date("2021-12-01T18:00:00-07:00"),
    new Date()
  )

  return areLightsOn ? <FeatureList /> : <CountdownToLightsOn />
}

function CountdownToLightsOn() {
  const [now, setNow] = useState(new Date())
  const [syncedWithClock, setSyncedWithClock] = useState(false)

  useEffect(() => {
    if (!syncedWithClock) {
      const theTimer = setTimeout(() => {
        setNow(new Date())
        setSyncedWithClock(true)
      }, differenceInSeconds(endOfMinute(now), now) * 1000)

      return () => clearTimeout(theTimer)
    }
  }, [syncedWithClock, now])

  useEffect(() => {
    if (syncedWithClock) {
      setNow(new Date()) // don't miss the leading tick
      const theTimer = setInterval(() => setNow(new Date()), 1000)
      return () => clearInterval(theTimer)
    }
  }, [syncedWithClock])

  return (
    <div className="flex flex-col items-center justify-center w-screen">
      <div className="min-w-min max-w-xl mt-4 sm:mt-8 mx-auto">
        <div className="mx-auto my-0 mb-8">
          <img
            alt="Lights on Leyden"
            className="mx-auto"
            loading="lazy"
            src={timTitleImg3}
          />
        </div>
        <div className={css.wreathWrapper}>
          <div className={css.wreathText}>
            <div className={css.timeUntil}>
              Lights turn on in <br />
              {timeTill(now, "2021-12-01T18:00:00-07:00") || "the past..."}
            </div>
          </div>
          <div className={css.wreath}>
            <img
              alt="pine wreath"
              loading="lazy"
              src={pineWreath}
              className={css.wreathImg}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoggedOut
